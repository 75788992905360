<!--<div class="map-wrapper">-->
  <app-user (preferencesButtonClicked)="handlePreferencesButtonClick($event)" (cartButtonClicked)="handleCartButtonClick($event)" #appuser></app-user>
  <app-cartography #cartography [hidden]="!cartographyVisible" class="right-click-container" ></app-cartography>

  <app-hypercube [hidden]="!hypercubeVisible"></app-hypercube>
  <app-login [hidden]="!loginVisible"></app-login>
  <app-preferences #preferences></app-preferences>
  <app-cart #cart></app-cart>
<!--
  <div class="menu-container">
    <mat-toolbar class="menu-toolbar">
      <button mat-button (click)="setMapVisibility(true)">{{ 'CARTOGRAPHY' | translate }}</button>
      <button mat-button (click)="setHypercubeVisibility(true)">{{ 'HYPERCUBE' | translate }}</button>
      <button mat-button >{{ 'REPORTS' | translate }}</button>
    </mat-toolbar>
  </div>
-->
<!--</div>-->

<!--
<ng-template #contextMenu>
  <div class="context-menu">
    <div class="menu-item" (click)="addRoiForRendezVous()">
      <div class="icon"><img class="img" src="assets/images/poi.png" alt="Icon 1"></div>
      <div class="text">Add ROI for Rendez-Vous</div>
    </div>
    <div class="menu-item">
      <div class="icon"><img class="img" src="path/to/your/icon2.png" alt="Icon 2"></div>
      <div class="text">Delete</div>
    </div>
  </div>
</ng-template>
-->

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';

interface CartItem {
  id: number;
  catalogueid: number;
  lat: number;
  long: number;
  acquisitiondate: Date;
  rollangle: number;
  name: string;
  quantity: number;
  price: number;
  orbite: number;
  type: string;
  localisation: string;
  level: string;
  buytype: string | null;
  satelliteId: string | null;
}


@Injectable({
  providedIn: 'root'
})
export class CartService {

  private itemsSubject = new BehaviorSubject<CartItem[]>([]);
  items$ = this.itemsSubject.asObservable();

  private apiUrl = environment.apiUrl;
  private endPointOrderRendezvous = 'orderrendezvous';
  private endPointGetOrders = 'getorders';
  private endPointOrderCatalog = 'ordercatalog';
  private endPointOrderRTCatalog = 'ordercatalogrt';

  constructor() { }

  addItem(item: CartItem) {
    console.log('addItem service');
    console.log(item);
    const currentItems = this.itemsSubject.value;
    this.itemsSubject.next([...currentItems, item]);
  }

  removeItem(item: CartItem) {
    const currentItems = this.itemsSubject.value.filter(i => i !== item);
    this.itemsSubject.next(currentItems);
  }

  getTotal() {
    return this.itemsSubject.value.reduce((acc, item) => acc + (item.price * item.quantity), 0);
  }

  orderItems(itemToOrder: CartItem) {
    console.log('orderItems');
    console.log(itemToOrder);

    if(itemToOrder && itemToOrder.type === 'Rendez-vous') {

      let parameters = {
        satelliteId: itemToOrder.satelliteId,
        lat: itemToOrder.lat,
        long: itemToOrder.long,
        acquisitiondate: itemToOrder.acquisitiondate,
        rollangle: itemToOrder.rollangle,
        orbite: itemToOrder.orbite,
      };
  
      fetch(`${this.apiUrl}/${this.endPointOrderRendezvous}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        credentials: 'include' as RequestCredentials,
        body: JSON.stringify(parameters),
        })
        .then(response => {
          if (response.status === 200) {
            const updatedItems = this.itemsSubject.value.filter(item => item !== itemToOrder);
            this.itemsSubject.next(updatedItems); // Mettre à jour et notifier les observateurs
            console.log('Rendezvous item supprimé avec succès.');
          } else {
            // Gérer les autres codes de réponse ou erreurs
            console.error('Erreur lors de la commande des items.');
          }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }
    else if(itemToOrder && itemToOrder.type === 'Image Catalog') {

      if(itemToOrder.buytype == 'catalogVersion')
      {
        let parameters = {
          //imagefileid: itemToOrder.id
          catalogueid: itemToOrder.catalogueid
        };
    
        fetch(`${this.apiUrl}/${this.endPointOrderCatalog}`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('token')
          },
          credentials: 'include' as RequestCredentials,
          body: JSON.stringify(parameters),
          })
          .then(response => {
            if (response.status === 200) {
              const updatedItems = this.itemsSubject.value.filter(item => item !== itemToOrder);
              this.itemsSubject.next(updatedItems); // Mettre à jour et notifier les observateurs
              console.log('Rendezvous item supprimé avec succès.');
            } else {
              // Gérer les autres codes de réponse ou erreurs
              console.error('Erreur lors de la commande des items.');
            }
          })
          .catch((error) => {
              console.error('Error:', error);
          });

      }
      else if(itemToOrder.buytype == 'retreatmentLevel')
      {
        let parameters = {
          imagefileid: itemToOrder.id,
          level: itemToOrder.level
        };
    
        fetch(`${this.apiUrl}/${this.endPointOrderRTCatalog}`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('token')
          },
          credentials: 'include' as RequestCredentials,
          body: JSON.stringify(parameters),
          })
          .then(response => {
            if (response.status === 200) {
              const updatedItems = this.itemsSubject.value.filter(item => item !== itemToOrder);
              this.itemsSubject.next(updatedItems); 
              console.log('Rendezvous item supprimé avec succès.');
            } else {
              // Gérer les autres codes de réponse ou erreurs
              console.error('Erreur lors de la commande des items.');
            }
          })
          .catch((error) => {
              console.error('Error:', error);
          });
      }


    }

    
  }

  /*getOrders(): any {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token') 
      },
      credentials: 'include' as RequestCredentials 
    };
  
    fetch(`${this.apiUrl}/${this.endPointGetOrders}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        credentials: 'include' as RequestCredentials,
        body: '',
        })
      .then(response => response.json())
      .then((data) => {
        console.log('backend response ' + JSON.stringify(data));
        return JSON.stringify(data);
  
  
  
        //this.rendezVousList.toggleRendezVous();
      })
      .catch((error) => {
          console.error('Error:', error);
      });
  }*/
    async getOrders(): Promise<any> {
        const options = {
            method: 'POST', // Assurez-vous que la méthode est correcte pour votre cas d'utilisation
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            credentials: 'include' as RequestCredentials,
            body: '', // Assurez-vous que le corps de la requête est correct pour votre cas d'utilisation
        };
    
        try {
            const response = await fetch(`${this.apiUrl}/${this.endPointGetOrders}`, options);
            const data = await response.json();
            console.log('backend response ' + JSON.stringify(data));
            return data; // Retourne les données directement au lieu de leur version en chaîne
        } catch (error) {
            console.error('Error:', error);
            throw error; // Propage l'erreur pour une gestion ultérieure
        }
    }

    itemIsInCart(item: CartItem): boolean {
        return this.itemsSubject.value.some(i => i.orbite === item.orbite && i.acquisitiondate === item.acquisitiondate && i.rollangle === item.rollangle && item.id === i.id);
    }

}




<!--<div [@slide]="isPanelOpen ? 'open' : 'closed'" class="panel">-->
  <div *ngIf="isPanelOpen" class="panel" [ngClass]="{'panel-expanded': isPanelExpanded}">
    <mat-icon class="expand-icon" (click)="togglePanelWidth()">
      {{ isPanelExpanded ? 'chevron_left' : 'chevron_right' }}
  </mat-icon>
    <p class="title">{{ 'LIBRARY' | translate }}</p>
    <mat-tab-group>
      <mat-tab label="Calques">
        <mat-card class="library-matcard">
          <!--<mat-card-header>
            <mat-card-title>{{ 'LIBRARY' | translate }}</mat-card-title>
          </mat-card-header>-->
          <mat-card-content>
            <div class="upper-half-old">
              <!--<button (click)="calculateSpectral()">Calculer
              </button>-->
                <mat-tree class="my-custom-tree" [dataSource]="dataSource" [treeControl]="treeControl">
                  <!-- Noeud sans enfants -->
                  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding (click)="onNodeClick(node)">
                    <div class="node-container">
                      <div class="title-container" (click)="onToggleVisible(node)">
                        <mat-icon>{{node.icon}}</mat-icon>
                        {{node.name}}
                      </div>
                      <div class="button-container"  *ngIf="node.parent" (click)="selectNode(node)">
                        <button class="mat-icon-button" *ngIf="node.parent" mat-icon-button [attr.aria-label]="node.visible ? 'Masquer' : 'Afficher'" (click)="onToggleVisible(node)">
                          <mat-icon>{{node.visible ? 'visibility' : 'visibility_off'}}</mat-icon>
                        </button>
                        <button class="mat-icon-button" *ngIf="node.name !== 'Carte de fond'" mat-icon-button [attr.aria-label]="'Éditer'" (click)="openEditDialog(node)">
                          <mat-icon>edit</mat-icon>
                        </button>
                        <button class="mat-icon-button" *ngIf="node.name !== 'Carte de fond'" mat-icon-button [attr.aria-label]="'Supprimer'" (click)="deleteNode(node)">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                  </div>
                  </mat-tree-node>
                  <!-- Noeud avec enfants -->
                  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                    <div class="mat-tree-node">
                      
                      <div class="title-container">
                        <button mat-icon-button matTreeNodeToggle class="mat-icon-button">
                          <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                          </mat-icon>
                        </button>
                        <mat-icon>{{node.icon}}</mat-icon>
                        {{node.name}}
                      </div>
                    </div>
                    <div [hidden]="!treeControl.isExpanded(node)">
                      <ng-container matTreeNodeOutlet>

                      </ng-container>
                    </div>
                  </mat-nested-tree-node>

                </mat-tree>
            </div>
            <!--
            <hr>
            <div class="lower-half-old">

              <canvas class="chartCanvas" baseChart
                [type]="'line'"
                [data]="lineChartData"
                [options]="lineChartOptions"
                [legend]="lineChartLegend">
              </canvas>
            </div>
            -->
          </mat-card-content>
        </mat-card>
      </mat-tab>
      <mat-tab label="Spectres"> 
        <div class="chart-container" [ngClass]="{'canvas-expanded': isPanelExpanded}">

          <canvas class="chartCanvas" baseChart
            [type]="'line'"
            [data]="lineChartData"
            [options]="lineChartOptions"
            [legend]="lineChartLegend">
          </canvas>

        </div> 
      </mat-tab>
    </mat-tab-group>

</div>
<!--
<div class="toggle-button" (click)="togglePanel()">
  <div [class.rotated]="!isPanelOpen" class="button-text">
      <p>{{ isPanelOpen ? '>>' : 'LIBRARY' | translate }}</p>
  </div>
</div>
  -->

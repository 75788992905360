 <div class="overlay">
  <div class="user-container">
      <div class="user-info" (click)="onPreferencesClick()">
        <mat-icon>person</mat-icon>
        <!--<span class="user-name">{{ userName }}</span>-->
      </div>
      <div class="cart" (click)="onCartClick()">
        <mat-icon>shopping_cart</mat-icon>
        <span class="cart-count" *ngIf="cartItemCount > 0">{{ cartItemCount }}</span>
        <!--<span class="user-name">{{ userName }}</span>-->
      </div>
    
    <div class="service-status">
        <span class="status-text">Hypercube</span>
        <svg class="status-icon" *ngIf="serviceState === 'loading'" width="20" height="20" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="8" stroke="transparent" stroke-width="2" fill="none"/>
            <circle cx="10" cy="10" r="8" stroke="white" stroke-width="2" fill="none" stroke-dasharray="50" stroke-dashoffset="50">
              <animate attributeName="stroke-dashoffset" from="50" to="0" dur="1s" repeatCount="indefinite"/>
            </circle>
          </svg>
        <svg *ngIf="serviceState === 'ok'" class="status-icon" viewBox="0 0 32 32">
            <circle cx="16" cy="16" r="15" fill="green" />
        </svg>
        <svg *ngIf="serviceState === 'ko'" class="status-icon" viewBox="0 0 32 32">
            <circle cx="16" cy="16" r="15" fill="red" />
        </svg>
    </div>
    <!--<button (click)="onLogoutClick()" class="logout-btn">{{ 'DISCONNECT' | translate }}</button>-->
  </div>
</div> 
import { OnInit, Component,  EventEmitter, Output, HostListener, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CartService } from '../cart.service';
import { Subscription, map } from 'rxjs';

@Component({
  selector: 'app-user',
  standalone: true,
  templateUrl: './user.component.html',
  imports: [ CommonModule, MatIconModule, TranslateModule, MatFormFieldModule, MatSelectModule],
  styleUrls: ['./user.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UserComponent {
  userImageURL: string = 'path-to-user-photo.jpg';
  userName: string | null = 'Nom de l\'utilisateur';
  serviceState: string = 'ko'; // peut être 'ok', 'ko' ou 'loading'
  cartItemCount: number = 0;
  private cartSubscription!: Subscription;

  @Output() preferencesButtonClicked = new EventEmitter<any>();
  @Output() cartButtonClicked = new EventEmitter<any>();


  constructor(private authService: AuthService, private router: Router, private translate: TranslateService, private cartService: CartService) {
    //this.authService.isAuthenticated$.subscribe(value => this.isAuth = value );
    //this.authService.isAuthenticated$.subscribe(value => console.log(value) );
    this.userName = this.authService.getUsername();
    this.authService.hypercubeStatus$.subscribe(value => this.serviceState = value );
    //translate.setDefaultLang('en');
    this.cartSubscription = this.cartService.items$
      .pipe(
        map(items => items.length) // Transforme le tableau d'items en sa longueur
      )
      .subscribe(count => {
        this.cartItemCount = count;
      });

  }

  async onLogoutClick() {
    console.log('onLogoutClick');
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  switchLanguage(language: string) {
    console.log('switchLanguage ' + language);
    this.translate.use(language);
  }

  onPreferencesClick(): void {
    this.preferencesButtonClicked.emit();
  }

  onCartClick(): void {
    this.cartButtonClicked.emit();
  }
}

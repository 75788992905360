import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComponentVisibilityService {
  private cartographyVisibility = new BehaviorSubject(true);
  private hypercubeVisibility = new BehaviorSubject(true);
  private loginVisibility = new BehaviorSubject(true);

  setLoginVisibility(isVisible: boolean) {
    this.loginVisibility.next(isVisible);
  }

  getLoginVisibility() {
    return this.loginVisibility.asObservable();
  }

  setCartographyVisibility(isVisible: boolean) {
    this.cartographyVisibility.next(isVisible);
  }

  getCartographyVisibility() {
    return this.cartographyVisibility.asObservable();
  }

  setHypercubeVisibility(isVisible: boolean) {
    this.hypercubeVisibility.next(isVisible);
  }

  getHypercubeVisibility() {
    return this.hypercubeVisibility.asObservable();
  }

  constructor() { }
}

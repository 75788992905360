
<div class="rendezvous-global-container" *ngIf="showCatalog">
    <mat-tab-group (selectedTabChange)="onTabChanged($event)">
      <mat-tab label="Catalog">
    
        <div class="rendezvous-container" >
    
          <!--
          <div *ngIf="!selectedItem">
          -->
    
            <button *ngIf="geoPoint && !selectedItem" class="settings-button" (click)="toggleSettings()">
              <mat-icon>sort</mat-icon>
            </button>
            <div *ngIf="!selectedItem" class="roi-button-container">
              <button *ngIf="removeButtonVisible" (click)="removeROIPoint()">Remove Region Of Interest</button>
              <button *ngIf="!removeButtonVisible" (click)="createROIPoint()">Add Region Of Interest</button>
            </div>
    
    
            <div class="settings-panel" *ngIf="showSettings && !selectedItem">
              <div class="settings-content">
                <mat-form-field appearance="fill">
                  <mat-label>Sort Column</mat-label>
                  <mat-select [(value)]="sortColumn" (selectionChange)="sortData()">
                    <mat-option *ngFor="let column of displayedColumns" [value]="column">
                      {{ column }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <button id="sortButton" (click)="toggleSortDirection()">
                  <mat-icon>{{sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward'}}</mat-icon>
                </button>
              </div> 
            </div>
    
            <div class="loading-overlay" *ngIf="isLoading && !selectedItem">Loading...</div>
            <div class="rendezvous-list" *ngIf="dataSource.data.length && !isLoading && !selectedItem; else noData">
              <div *ngFor="let element of dataSource.data" (click)="onCatalogClick(element)" (mouseover)="onItemHover(element)" (mouseleave)="onItemLeave()" class="rendezvous-item" [class.selected]="element === selectedItem">
                <div class="left-column">
                </div>
                <div class="right-column">
                  <div class="detail-row">
                    <span class="detail-key"><strong>Localisation:</strong></span>
                    <span class="detail-value">{{element.localisation}}</span>
                  </div>
                  <div class="detail-row">
                    <span class="detail-key"><strong>Level:</strong></span>
                    <span class="detail-value">{{element.level}}</span>
                  </div>
                  <div class="detail-row">
                    <span class="detail-key"><strong>Distance:</strong></span>
                    <span class="detail-value">{{element.distanceKm}}</span>
                  </div>
                </div>
              </div>
            </div>
    
            <ng-template #noData>
              <div *ngIf="!selectedItem && !isLoading && !dataSource.data.length" class="centered-content">No Datas</div>
            </ng-template>
            <!--
          </div>
          -->
    
          <div *ngIf="selectedItem" class="selected-item-details">

            <button (click)="backToList()">Retour</button>
            <div>
              <div class="detail-row">
                <span class="detail-key"><strong>Localisation:</strong></span>
                <span class="detail-value">{{selectedItem.localisation}}</span>
              </div>
              <div class="detail-row">
                <span class="detail-key"><strong>Level:</strong></span>
                <span class="detail-value">{{selectedItem.level}}</span>
              </div>
              <div class="detail-row">
                <span class="detail-key"><strong>Distance:</strong></span>
                <span class="detail-value">{{selectedItem.distanceKm}}</span>
              </div>
              <!-- Bouton radio pour "Catalog version" -->
              <input type="radio" id="catalogVersion" name="selection" value="catalogVersion" [(ngModel)]="selectedOption">
              <label for="catalogVersion">Catalog version</label>

              <!-- Bouton radio pour "Retreatment level" -->
              <input type="radio" id="retreatmentLevel" name="selection" value="retreatmentLevel" [(ngModel)]="selectedOption">
              <label for="retreatmentLevel">Retreatment level</label>

              <!-- Combobox liée au bouton radio "Retreatment level" -->
              <select id="levelSelect" [(ngModel)]="selectedItem.selectedLevel" *ngIf="selectedOption === 'retreatmentLevel'">
                <option *ngFor="let level of processLevels" [value]="level">{{ level }}</option>
              </select>
              <div class="button-container">
                <button class="add-to-cart-button" (click)="addItemToCart()" [disabled]="!selectedOption || (selectedOption === 'retreatmentLevel' && !selectedItem.selectedLevel)">
                  🛒 Add to cart
                </button>
              </div>
            </div>
          </div>

        </div>
    
      </mat-tab>
      <mat-tab label="Orders">
        <div class="orders-container">
          <div *ngFor="let element of orderDataSource.data" (click)="onCatalogClick(element)" (mouseover)="onOrderItemHover(element)" (mouseleave)="onItemLeave()" class="rendezvous-item" [class.selected]="element === selectedItem">
            <div class="left-column">
            </div>
            <div class="right-column">
              <div class="detail-row">
                <span class="detail-key"><strong>Order Id:</strong></span>
                <span class="detail-value">{{element.OrderId}}</span>
              </div>
              <div class="detail-row">
                <span class="detail-key"><strong>Status:</strong></span>
                <span class="detail-value">{{element.Statut}}</span>
              </div>
              <div class="detail-row">
                <span class="detail-key"><strong>OrderType:</strong></span>
                <span class="detail-value">{{element.OrderType}}</span>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
    </div>
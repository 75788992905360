
<as-split direction="vertical" (dragEnd)="onDragEnd($event)" [ngClass]="{'hide-gutter': bottomAreaSize === 0}" >
    <as-split-area [size]="topAreaSize">
     <!--<as-split-area [size]="100">-->
        <div id="map" [class.appstore-open]="appStoreVisible" [class.adjusted-size]="codeEditorVisible" >

        </div>
        <div #mapToolbar id="map-toolbar" [ngStyle]="{'display': (isToolbarVisible$ | async) ? 'flex' : 'none'}">
            <div id="drag-handle" (mousedown)="onToolbarDragStart($event)">⠿</div>
            <button mat-flat-button class="map-toolbar-button" (click)="moveSelectedObject()">
                <mat-icon>open_with</mat-icon>
            </button>
            <button class="map-toolbar-button" (click)="deleteSelectedObject()">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </as-split-area>
    <as-split-area class="bottom-area" [size]="bottomAreaSize">
    <!--<as-split-area class="bottom-area" [size]="0" >-->
        <app-codeeditor  #codeEditor></app-codeeditor>
    </as-split-area>
</as-split>
  

<app-hypercube-selector (hypercubeChanged)="hypercubeChanged($event)" (mapClicked)="handleHypercubeSelectorMapClicked($event)" (displayMarkersChange)="handleDisplayMarkersChange($event)" #hypercubeSelector></app-hypercube-selector>
<app-appstore (appStoreVisibleChange)="onAppStoreVisibleChange($event)" (mapClicked)="handleAppStoreMapClicked($event)" (buttonClicked)="handleButtonClick($event)" #appStore></app-appstore>
<!--<app-band-selector (buttonClicked)="handleBandButtonClick($event)"></app-band-selector>-->
<app-library (toggleVisible)="layerVisibilityChanged($event)" (deletingNode)="nodeDeleted($event)" (editingNode)="nodeEdited($event)" (selectingNode)="nodeSelected($event)" (calculate)="calculate()" #layerLibrary></app-library>
<!--<app-rendezvous (RendezVousVisibleChange)="onRendezVousVisibleChange($event)" (geoPointChange)="onNewRendezVousGeoPoint($event)" (rendezvousfootprintChange)="onNewRendezVousFootprint($event)" #RendezVousList></app-rendezvous>-->
<app-rendezvous (RendezVousVisibleChange)="onRendezVousVisibleChange($event)" (rendezvousfootprintChange)="onNewRendezVousFootprint($event)" #RendezVousList></app-rendezvous>
<app-catalog (rendezvousfootprintChange)="onNewRendezVousFootprint($event)" #Catalog></app-catalog>

<ng-template #contextMenu>
  <div class="context-menu">
    <div class="menu-item" *ngIf="!rendezvousMarker" (click)="addRoiForRendezVous()">
      <div class="icon"><img class="img" src="assets/images/poi.png" alt="ROI"></div>
      <div class="text" >Add ROI</div>
    </div>
    <div class="menu-item" *ngIf="rendezvousMarker" (click)="removeRoiForRendezVous()">
      <div class="icon"><img class="img" src="assets/images/poi.png" alt="ROI"></div>
      <div class="text" >Remove ROI</div>
    </div>
  </div>
</ng-template>
<h2 mat-dialog-title>Éditer le nom du noeud</h2>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>Nom du noeud</mat-label>
    <input matInput [(ngModel)]="data.nodeName">
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="cancel()">Annuler</button>
  <button mat-button (click)="save()">Enregistrer</button>
</mat-dialog-actions>
<!--<div>
    <button mat-icon-button (click)="closeDialog()" style="position: absolute; top: 0; right: 0;">
        <mat-icon>close</mat-icon>
    </button>
    <div [innerHTML]="data.htmlContent"></div>
</div>-->
<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>{{ data.content }}</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="closeDialog()">{{ data.noButtonText }}</button>
</mat-dialog-actions>
    

<div class="appstore-container" *ngIf="showAppStore" (click)="$event.stopPropagation()" #appStore>
    <p class="title">
        {{ 'MY_APPS' | translate }}
    </p>
    <div class="appstore-content">
<!--
        <div>
            <h2>Mes applications</h2>
            <input [(ngModel)]="searchQuery" (input)="onSearch()" placeholder="Rechercher">
            <div *ngFor="let app of ownedApps" class="app-container">
            <img [src]="app.image" [alt]="app.name">
            <h3>{{app.name}}</h3>
            <p>{{app.description}}</p>
            <div *ngFor="let i of ' '.repeat(app.rating).split(''), let idx = index">
                ⭐
            </div>
            </div>
        </div>

        <div>
            <h2>Applications disponibles</h2>
            <input [(ngModel)]="searchQuery" (input)="onSearch()" placeholder="Rechercher">
            <div *ngFor="let app of availableApps" >
            <img [src]="app.image" [alt]="app.name">
            <h3>{{app.name}}</h3>
            <p>{{app.description}}</p>
            <div *ngFor="let i of ' '.repeat(app.rating).split(''), let idx = index">
                ⭐
            </div>
            </div>
        </div>
    -->

    <!--<mat-tab-group>
        <mat-tab [label]="'MY_APPS' | translate">

        <mat-tab>
            <div class="appstore-tab-content">
                <input class="search-bar" [(ngModel)]="searchQuery" (input)="onSearch()" [placeholder]="'SEARCH' | translate">
                <div *ngFor="let app of ownedApps" class="app-container">
                    <div class="image-container"> 
                        <img [src]="app.image" [alt]="app.name">
                    </div>
                    <div class="app-info">
                        <div class="app-description">
                            <h3 class="label-button" (click)="onLabelClick(app)">{{app.name | translate}}</h3>
                            <div class="select-container">
                                <select class="app-parameter" *ngIf="app.valuesInfo" [(ngModel)]="app.selectedValue">
                                    <option *ngFor="let item of app.valuesInfo" [value]="item.id">{{item.value}}</option>
                                </select>
                                <mat-icon *ngIf="app.values" (click)="openInfoModal(app)" class="info-icon">info</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="app.values" class="button-container"> 
                        <button class="app-button" (click)="onButtonClick(app)">{{ 'APPLY' | translate}}</button>
                    </div>

                </div>
            </div>
        </mat-tab>
      </mat-tab-group>-->

      <div class="appstore-tab-content">
        <input class="search-bar" [(ngModel)]="searchQuery" (input)="onSearch()" [placeholder]="'SEARCH' | translate">
        <div *ngFor="let app of ownedApps" class="app-container">
            <div class="image-container"> 
                <img [src]="app.image" [alt]="app.name">
            </div>
            <div class="app-info">
                <div class="app-description">
                    <h3 class="label-button" (click)="onLabelClick(app)">{{app.name | translate}}</h3>
                    <div class="select-container">
                        <select class="app-parameter" *ngIf="app.valuesInfo" [(ngModel)]="app.selectedValue">
                            <option *ngFor="let item of app.valuesInfo" [value]="item.id">{{item.value}}</option>
                        </select>
                        <mat-icon *ngIf="app.values" (click)="openInfoModal(app)" class="info-icon">info</mat-icon>
                    </div>
                </div>
            </div>
            <div *ngIf="app.values" class="button-container"> 
                <button class="app-button" (click)="onButtonClick(app)">{{ 'APPLY' | translate}}</button>
            </div>

        </div>
    </div>

    </div>

</div>
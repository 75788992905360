import { Component, EventEmitter, Output, Renderer2, ElementRef, ViewChild, AfterViewInit, OnInit  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MonacoEditorModule, MonacoEditorConstructionOptions } from '@materia-ui/ngx-monaco-editor';
import { environment } from '../../environments/environment';
import { CommonModule } from '@angular/common';
import { AngularSplitModule } from 'angular-split';

@Component({
  selector: 'app-codeeditor',
  standalone: true,
  imports: [FormsModule, MonacoEditorModule, CommonModule, AngularSplitModule],
  templateUrl: './codeeditor.component.html',
  styleUrls: ['./codeeditor.component.css']
})

export class CodeeditorComponent implements AfterViewInit, OnInit{
  @Output() codeEditorVisibleChange = new EventEmitter<boolean>();
  public code = 'def functiontest():\n  print(cube);\nfunctiontest();';;
  public output = '';
  public result = '';
  public error_occured = '';
  public error_output = '';
  public editorOptions = { language: 'python', theme: 'vs-dark' };
  showCodeEditor = false;

  private apiUrl = environment.apiUrl;
  private endPoint = 'execute';

  isComponentVisible: boolean = true;
  private startY!: number;
  private startHeight!: number;
  private mouseMoveListener: Function | null = null;
  private mouseUpListener: Function | null = null;

  @ViewChild('resizableComponent', { static: false }) resizableComponent!: ElementRef;
  @ViewChild('resizeBar') resizeBar!: ElementRef;

  tree = [
    {
      name: '.vscode',
      content: [{ name: 'settings.json' }],
    },
    {
      name: 'src',
      content: [
        {
          name: 'app',
          content: [
            { name: 'app.component.html' },
            { name: 'app.component.css' },
            { name: 'app.component.spec.ts' },
            { name: 'app.component.ts' },
            { name: 'app.module.ts' },
          ],
        },
        {
          name: 'assets',
          content: [{ name: '.gitkeep' }],
        },
        {
          name: 'environments',
          content: [
            { name: 'environment.prod.ts' },
            { name: 'environment.ts' },
          ],
        },
        {
          name: 'favicon.ico',
        },
        {
          name: 'index.html',
        },
        {
          name: 'main.ts',
        },
        {
          name: 'polyfill.ts',
        },
        {
          name: 'styles.css',
        },
      ],
    },
    {
      name: 'angular.json',
    },
    {
      name: 'package-lock.json',
    },
    {
      name: 'package.json',
    },
    {
      name: 'tsconfig.json',
    },
  ];

  constructor(private http: HttpClient, private renderer: Renderer2) {
    
  }

  ngOnInit(): void {
    this.toggleCodeEditor();
  }

  ngAfterViewInit() {
    
  }
  //constructor(){}

  toggleComponentVisibility(): void {
    this.isComponentVisible = !this.isComponentVisible;
  }

  toggleCodeEditor() {
    this.showCodeEditor = !this.showCodeEditor;
    this.codeEditorVisibleChange.emit(this.showCodeEditor);
  }

  isVisible(): boolean{
    return this.showCodeEditor;
  }

  onExecuteClick(): void {
    this.runCode();
  }

  runCode() {
    //this.http.post<{result: string, output: string, error_occurred: string, error_output: string}>('http://localhost:3000/execute', { code: this.code })
    
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')  // Assurez-vous que le préfixe "Bearer " est correct.
      },
      credentials: 'include' as RequestCredentials  // Important si vous utilisez des sessions ou des cookies.
    };
    
    
    
    this.http.post<{result: string, output: string, error_occurred: string, error_output: string}>(`${this.apiUrl}/${this.endPoint}`, { code: this.code }, options)
    .subscribe({
      next: (data) => { this.output = data.output; this.result = data.result; this.error_occured = data.error_occurred;this.error_output = data.error_output;},
      error: (error) => { console.error(error); }
    });
  }


    
}

<!--
<div class="login-container">
    <h2>Connexion</h2>
  
    <div class="form-group">
      <label for="username">Nom d'utilisateur:</label>
      <input type="text" id="username" [(ngModel)]="username" name="username" required>
    </div>
  
    <div class="form-group">
      <label for="password">Mot de passe:</label>
      <input type="password" id="password" [(ngModel)]="password" name="password" required>
    </div>
  
    <button (click)="onLoginClick()">Se connecter</button>
  
    <p *ngIf="errorMessage" class="error-message">{{errorMessage}}</p>
  </div>
-->
<div class="b">
  <div class="container">
    <div class="d-flex justify-content-center h-100">
      <div class="card">
        <div class="card-header">
          <h3>Login</h3>
          <div class="d-flex justify-content-end social_icon">
            <span><i class="fab fa-facebook-square"></i></span>
            <span><i class="fab fa-google-plus-square"></i></span>
            <span><i class="fab fa-twitter-square"></i></span>
          </div>
        </div>
        <div class="card-body">
          <form *ngIf="isAuth === false">
            <div class="input-group form-group">
              <!--<div class="input-group-prepend">
                <span class="input-group-text"><mat-icon>person</mat-icon></span>
                
              </div>-->
              <input type="text" id="username" class="form-control" placeholder="username" [(ngModel)]="username" name="username" required>
              
            </div>
            <div class="input-group form-group">
              <!--<div class="input-group-prepend">
                <span class="input-group-text"><mat-icon> vpn_key</mat-icon></span>
              </div>-->
              <input type="password" id="password" class="form-control" placeholder="password" [(ngModel)]="password" name="password" required>
            </div>
            <div class="form-group">
              <button (click)="onLoginClick()" class="btn float-right login_btn">Log in</button>
            </div>
          </form>
          <div *ngIf="isAuth">
            <div>
              <span class="title-username">{{ username }}</span>
            </div>
            <select [(ngModel)]="selectedGroup" name="groupSelect">
              <option [ngValue]="null" disabled [hidden]="selectedGroup != null">Choose your workgroup...</option>
              <option *ngFor="let group of groups" [ngValue]="group.id">{{group.nom}}</option>
            </select>
            <button class="validateButton" (click)="onGroupSelect()">Validate</button>
            <button class="cancelButton" (click)="cancelLogin()">Cancel</button>
          </div>
          <div>
            <p *ngIf="errorMessage" class="error-message">{{errorMessage}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
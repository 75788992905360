import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Hypercube } from './hypercube';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HypercubesService {

  private apiUrl = environment.apiUrl;
  private endPointHypercubes = 'hypercubes';

  private options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      //'Authorization': 'Bearer ' + localStorage.getItem('token')
    },
    //credentials: 'include' as RequestCredentials 
  };



  constructor() { }


  /*async getHypercubes(): Promise<Hypercube[]> {
    let hypercubesList: Hypercube[] = [];
    console.log('getHypercubes');
    return fetch(`${this.apiUrl}/${this.endPointHypercubes}`, this.options)
    //return fetch(`${this.apiUrl}/${this.endPointHypercubes}`)
    .then(response => response.json())
    .then((data) => {
      let hcs = data.map(item => ({
        id: item.id,
        name: item.name,
        image_url: item.imageUrl
      }))
      return hcs;
    });
    
  }*/
}

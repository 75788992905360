import { Component, Input, EventEmitter, Output, HostListener, ViewChild, ElementRef } from '@angular/core';
import { HypercubesService } from '../hypercubes.service';
import { Hypercube } from '../hypercube';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-hypercube-selector',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatCardModule],
  templateUrl: './hypercube-selector.component.html',
  styleUrls: ['./hypercube-selector.component.css']
})
export class HypercubeSelectorComponent {
  isExpanded = false;
  @Output() hypercubeChanged = new EventEmitter<any>();
  hypercubes: Hypercube[] = [];
  portfolio: Hypercube[] = [];
  @ViewChild('hypercubeSelector') hypercubeSelector!: ElementRef;

  @Output() mapClicked = new EventEmitter<any>();
  @Output() displayMarkersChange = new EventEmitter<boolean>();


  constructor(private hypercubesService: HypercubesService, private authService: AuthService, public dialog: MatDialog, private translate: TranslateService) {
    this.authService.Hypercubes$.subscribe(value => this.hypercubes = value );
    console.log('constructor hypercubeselector');
    console.log(this.hypercubes);


  }

  async ngOnInit() {
    //this.hypercubes =  await this.hypercubesService.getHypercubes();
  }

  onDisplayMarkersChange(event: any) {
    this.displayMarkersChange.emit(event.target.checked);
  }

  toggleView() {
    this.isExpanded = !this.isExpanded;
  }

  /*openConfirmationDialog(hypercube: Hypercube): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        title: 'Confirmer chargement de l\'hypercube',
        content: 'Êtes-vous sûr de vouloir charger l\'hypercube ' + hypercube.name + ' ? ',
        noButtonText: 'Non',
        yesButtonText: 'Oui'
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadHypercube(hypercube);
        
      }
    });

  }*/

  openConfirmationDialog(hypercube: Hypercube): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        title: 'Confirmer chargement de l\'hypercube',
        content: 'Êtes-vous sûr de vouloir charger l\'hypercube ' + hypercube.country + ' ? ',
        noButtonText: 'Non',
        yesButtonText: 'Oui'
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadHypercube(hypercube);
        
      }
    });

  }

  async loadHypercube(hypercube: Hypercube) {
    console.log('Loading hypercube ' + hypercube.name);

    //await this.authService.loadHypercube(hypercube.id);
    await this.authService.loadHypercube(hypercube);
    console.log('emiting hypercubeChanged ');
    this.hypercubeChanged.emit(hypercube);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if(this.isExpanded && this.hypercubeSelector && !this.hypercubeSelector.nativeElement.contains(event.target))
    {
      console.log(event.target);
      const item = event.target as HTMLElement;
      if(item.id === "map")
      {
        console.log('Click on map : close hypercubeSelector');
        //this.toggleAppStore();
        this.mapClicked.emit();
      }
    }
  }

}

import { Component, Input, EventEmitter, Output, HostListener, } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NestedTreeControl} from '@angular/cdk/tree';
import { MatTree, MatTreeModule, MatTreeNestedDataSource} from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';
import { Treenode } from '../treenode';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { EditNodeDialogComponent } from '../edit-node-dialog/edit-node-dialog.component';
import { ChartConfiguration, ChartOptions, ChartType, ChartDataset  } from "chart.js";

import { NgChartsModule  } from 'ng2-charts';

import {parse, stringify, toJSON, fromJSON} from 'flatted';
import * as L from 'leaflet';
import { PlotlyViaCDNModule } from 'angular-plotly.js';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'app-library',
  standalone: true,
  imports: [ MatTreeModule, MatIconModule, MatCardModule, CommonModule, NgChartsModule, PlotlyViaCDNModule, TranslateModule, MatTabsModule ],
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.css'],
  animations: [
    trigger('slide', [
      state('closed', style({ transform: 'translateX(100%)' })),
      state('open', style({ transform: 'translateX(0%)' })),
      transition('open <=> closed', [
        animate('0.3s')
      ]),
    ])
  ]
})
export class LibraryComponent {

  treeControl = new NestedTreeControl<Treenode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<Treenode>();
  @Output() libraryVisibleChange = new EventEmitter<boolean>();
  @Output() toggleVisible = new EventEmitter<any>();
  @Output() deletingNode = new EventEmitter<any>();
  @Output() editingNode = new EventEmitter<any>();
  @Output() creatingNodeNameInAdvance = new EventEmitter<any>();
  @Output() selectingNode = new EventEmitter<any>();
  @Output() calculate = new EventEmitter<any>();
  @Input() data: Treenode[] = [];
  isPanelOpen = false;
  showLibrary = false;
  dataArray: any;
  plotlyData: any;
  layout: any;

  isPanelExpanded = false;


  constructor(private dialog: MatDialog) {

      
  }

  togglePanelWidth() {
    this.isPanelExpanded = !this.isPanelExpanded;
  }


  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  
  
  public barChartLabels = ['Label 1', 'Label 2', 'Label 3'];
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [
    {data: [65, 59, 80], label: 'Series A'},
    {data: [28, 48, 40], label: 'Series B'}
  ];

  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: [],
    datasets: []
  };

  public lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point:{
          radius: 0
      }
  }
  };
  public lineChartLegend = false;







  togglePanel() {
    this.isPanelOpen = !this.isPanelOpen;
    this.libraryVisibleChange.emit(this.isPanelOpen);
  }

  openEditDialog(node: Treenode): void {
    const dialogRef = this.dialog.open(EditNodeDialogComponent, {
      data: { nodeName: node.name }
    });
  
    dialogRef.afterClosed().subscribe(newName => {
      if (newName) {
        node.name = newName;
        (node.object as any).properties.name = newName;
        this.editingNode.emit(node.name);
      }
    });
  }

  async openCreateNodeNameDialog(name: string): Promise<any> {
    const dialogRef = this.dialog.open(EditNodeDialogComponent, {
      data: { nodeName: name }
    });
  
    /*dialogRef.afterClosed().subscribe(newName => {
      return newName;
    });*/
    return dialogRef.afterClosed()
    .toPromise() // here you have a Promise instead an Observable
    .then(result => {
        return Promise.resolve(result); // will return a Promise here
    });
  }

  selectNode(node: Treenode): void {
    this.selectingNode.emit(node);
  }

  ngOnInit() {
    this.dataSource.data = this.data;

    /*let newNode: Treenode = { name: 'Calques', object: new Object, children: [
      { name: 'Child Node 1', object: new Object },
      { name: 'Child Node 2', object: new Object },
    ]};
    let parentNode = this.data[0]; // le premier nœud de l'arbre
    this.addNode(newNode, parentNode);

    let newNode2: Treenode = { name: 'Points', object: new Object, children: [
      { name: 'Child Node 1', object: new Object },
      { name: 'Child Node 2', object: new Object },
    ]};
    this.addNode(newNode2);*/
  }

  addNode(node: Treenode, parentNode?: Treenode) {
    if (!parentNode) {
      // Ajoute un nouveau noeud à la racine si aucun noeud parent n'est spécifié
      this.data.push(node);
    } else {
      // Ajoute un nouveau noeud comme enfant du noeud parent spécifié
      if (!parentNode.children) {
        parentNode.children = [];
      }
      parentNode.children.push(node);
    }

    // Met à jour la source de données pour déclencher le rendu du nouveau noeud
    //this.dataSource.data = null;
    this.dataSource.data = this.data;
  }

  refreshNodes(){
    console.log('refresh nodes');
    this.dataSource.data = this.data;
  }

  deleteNode(node: Treenode): void {
    console.log('deleteNode');
    const parent = node.parent;
    

    if (parent && parent.children) {
      console.log('parent = ' + parent.name);
      const index = parent.children.indexOf(node);
      console.log('index ' + index);
      if (index > -1) {
        parent.children.splice(index, 1);
        console.log('New datasource');
        this.deletingNode.emit(node);
        const data = this.removeNodeAndDescendants(this.dataSource.data, node);
        console.log(data);
        this.dataSource.data = [];
        this.dataSource.data = data;
      }
    }
  }
  
  removeNodeAndDescendants(nodes: Treenode[], targetNode: Treenode): Treenode[] {
    console.log('removeNodeAndDescendants');
    let newNodes: Treenode[] = [];
    console.log(nodes);
    for (let node of nodes) {
      console.log('for node');
      console.log(node);
      if (node !== targetNode) {
        console.log('node !== targetNode');
        if (node.children && node.children.length > 0) {
          console.log('node.children && node.children.length > 0');
          node.children = this.removeNodeAndDescendants(node.children, targetNode);
        }
        newNodes.push(node);
      }
    }
    return newNodes;
  }


  updateChart(profils: Set<Object>){

    const options = {
      elements: {
        point: {
          radius: 1,
          hoverRadius: 2, // ex.: to make it bigger when user hovers put larger number than radius.
        }
      }
    }

    let labelsArray: any[] = [];
    let datasArray: any[] = [];

    console.log('updateChart');

    console.log(profils);
  
    profils.forEach( (profil)=> {
      console.log(profil);
      console.log((profil as any).properties.name);
      labelsArray.push((profil as any).properties.name);
      datasArray.push({data:(profil as any).properties.reflectance_profile, label:(profil as any).properties.name});
    })

    console.log(labelsArray)
    
    console.log(datasArray)

    this.lineChartData =
    {
      labels: labelsArray,
      datasets: datasArray

    };

    
    let labels = [];
    let datasets = [];
    /*profils.forEach(function (item) {
      labels.push((item as any).properties.name);
    });*/


  }

  onNodeClick(node: Treenode) {
    console.log('Node clicked: ', node);
    
    this.selectingNode.emit(node);
    // ajoutez ici votre logique de gestion du clic sur la node
  }

  createNode(name: string, object: Object, geoJson: Object | null, icon: string, parent?: string){
    console.log("create node");
    console.log("icon " + icon );
    let newNode: Treenode = { name: name, object: object, geojson: geoJson, visible: true, parent: null, icon: icon};
    if (!parent) {
      this.data.push(newNode);
    } else {
      console.log("else");
      let parentNode: Treenode | null = this.getNodeByName(parent);
      newNode.parent = parentNode;
      console.log(parentNode);
      if(parentNode != null){
        if (parentNode.children == null) {
          parentNode.children = [];
        }
        console.log("parent found");
        console.log(parentNode);
        console.log(newNode);
        parentNode.children.push(newNode);
        //this.treeControl.expand(newNode);
      }
    }
    //this.dataSource.data = this.data;

    const data = this.dataSource.data;
    this.dataSource.data = [];
    this.dataSource.data = data;

    return newNode;
  }

  calculateSpectral() {
    console.log('Node calculateSpectral: ');
    this.calculate.emit();
  /*  
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')  // Assurez-vous que le préfixe "Bearer " est correct.
      },
      credentials: 'include' as RequestCredentials  // Important si vous utilisez des sessions ou des cookies.
    };

    fetch(`http://localhost:3000/calculate`, options)
      .then(response => {
        // Vérifie si la requête a été réussie, sinon rejette la promesse
        console.log(response);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();  // Convertit la réponse en JSON
      })
      .then(data => {
          console.log(data);
          this.dataArray = data;

          const x_coords = data.coords.x.data;
          const y_coords = data.coords.y.data;
          const dataArray = data.data_vars.data.data;
    
          this.plotlyData = [{
            x: x_coords,
            y: y_coords,
            z: dataArray,
            type: 'heatmap'
          }];
    
          this.layout = {
            title: 'NDVI<br><sup>Normalized Difference Vegetation Index</sup><br><sup>(N-R)/(N+R)</sup>',
            autosize: true,
          };
      })
      .catch(error => {
          console.error('Erreur lors de la récupération des données:', error);
      });
      */
  }

  getNodeByName(name: string): Treenode | null {
    for (let node of this.dataSource.data) {
      console.log(node.name);
      console.log(name);
      if (node.name === name) {
        return node;
      }
    }
    return null;
  }

  onToggleVisible(node: Treenode) {
    node.visible = !node.visible;
    this.toggleVisible.emit(node);
  }


  hasChild = (_: number, node: Treenode) => !!node.children && node.children.length > 0;

  hasParent(node: Treenode){
    if(node.parent){
      return true;
    }
    else{
      return false;
    }
  }

  convertTreeToJSON(node: Treenode): any {
    const jsonObject: any = {
      name: node.name,
      object: null,
      GeoJSON: node.geojson, 
      visible: node.visible,
      icon: node.icon
    };
    if (node.children && node.children.length > 0) {
      jsonObject.children = node.children.map(child => this.convertTreeToJSON(child));
    }
    return jsonObject;
  }

  /*exportTreeDataToJSON(dataSource: MatTreeNestedDataSource<Treenode>): string {
    const rootNodes = dataSource.data;
    const jsonArray = rootNodes.map(rootNode => this.convertTreeToJSON(rootNode));
    return JSON.stringify(jsonArray, null, 2);  // Le troisième argument est pour la mise en forme.
  }*/
  exportTreeDataToJSON(): string {
    const rootNodes = this.dataSource.data;
    const jsonArray = rootNodes.map(rootNode => this.convertTreeToJSON(rootNode));
    console.log(JSON.stringify(jsonArray, null, 2));
    return JSON.stringify(jsonArray, null, 2);  // Le troisième argument est pour la mise en forme.
  }





}

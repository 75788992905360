import { Component, OnInit, OnDestroy, ElementRef, ViewChild,AfterViewInit  } from '@angular/core';
import { CodeeditorComponent } from '../codeeditor/codeeditor.component';

@Component({
  selector: 'app-hypercube',
  standalone: true,
  imports: [CodeeditorComponent],
  templateUrl: './hypercube.component.html',
  styleUrls: ['./hypercube.component.css']
})
export class HypercubeComponent implements AfterViewInit, OnDestroy {
  @ViewChild('rendererContainer') rendererContainer!: ElementRef;

  /*renderer = new THREE.WebGLRenderer();
  scene = THREE.Scene;
  camera: THREE.PerspectiveCamera;
  material = null;
  images = ['https://www.sophiaengineering.com/wp-content/themes/sophia/assets/svg/logo-noir.svg', 'https://www.sophiaengineering.com/wp-content/themes/sophia/assets/svg/logo-noir.svg', 'https://www.sophiaengineering.com/wp-content/themes/sophia/assets/svg/logo-noir.svg']; // ...your images here
  frameId: number | null = null;*/

  ngAfterViewInit() {

    /*this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    if (this.camera && this.camera.position) {
      this.camera.position.z = 5;
    }

    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);*/

    /*this.images.forEach((image, index) => {
      const texture = new THREE.TextureLoader().load(image);
      const material = new THREE.MeshBasicMaterial({ map: texture });
      const geometry = new THREE.PlaneGeometry(1, 1);
      const mesh = new THREE.Mesh(geometry, material);
      mesh.position.set(0, 0, -index * 0.1); // Offset each image slightly on the Z axis to create a "stacked" effect
      if(this.scene)
      {
        this.scene.add(mesh);
      }
      
    });

    this.animate();*/
  }

  ngOnDestroy() {
    /*if (this.frameId != null) {
      cancelAnimationFrame(this.frameId);
    }*/
  }

  animate = () => {
    /*requestAnimationFrame(this.animate);
    this.frameId = requestAnimationFrame(this.animate);
    this.renderer.render(this.scene, this.camera);*/
  }
}

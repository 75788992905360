import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loadingBandsSubject = new BehaviorSubject<boolean>(false);

  constructor() { }


  showLoadingBands() {
    this.loadingBandsSubject.next(true);
  }

  hideLoadingBands() {
    this.loadingBandsSubject.next(false);
  }

  getLoadingBandsStatus() {
    return this.loadingBandsSubject.asObservable();
  }
}

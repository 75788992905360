<div class="container" *ngIf="isExpanded" (click)="$event.stopPropagation()" #hypercubeSelector>
    <p class="title">
        {{ 'HYPERCUBE_CATALOG' | translate }}
    </p>
    <div>
        <!--<input id="display-ground-marker" type="checkbox" checked (change)="onDisplayMarkersChange($event)">-->
        <!--<label for="display-ground-marker">Display ground markers</label>-->
    </div>
    
    <div class="header">
        <input class="search-bar" [placeholder]="'SEARCH' | translate">
    </div>

    <div class="image-grid" >
        
        <div *ngFor="let hypercube of hypercubes" class="image-cell">
            <div class="image-container" (click)="openConfirmationDialog(hypercube)">
                <img [src]="hypercube.thumbnail" alt="">
                <!--<p class="text-line1">{{hypercube.name}}</p>-->
                <p class="text-line1">{{hypercube.country}}</p>
            </div>
        </div>
        
        <!--
        <div *ngFor="let hypercube of portfolio" class="image-cell">
            <div class="image-container" (click)="openConfirmationDialog(hypercube)">
                <img [src]="hypercube.thumbnail" alt="">
                <p class="text-line1">{{hypercube.name}}</p>
                <p class="text-line2">{{hypercube.country}}</p>
            </div>
        </div>
        -->
    </div>
</div>
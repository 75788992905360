import { OnInit, Injectable } from '@angular/core';
import { Appstore, AppParam } from './appstore';
import { SpectralIndex } from './spectral-index';
import { environment } from '../environments/environment';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class AppstoreService {

  private apiUrl = environment.apiUrl;
  private endPointSpectralIndices = 'spectral_indices_list';
  private spectralIndicesLoaded = false;

  constructor(private authService: AuthService) { 
    console.log('AppstoreService constructor');
    this.authService.hypercubeStatus$.subscribe(value => this.CheckSpectralIndicesLoaded(value) );
  }

  CheckSpectralIndicesLoaded(hypercubeSatus : any | null){
    console.log('CheckSpectralIndicesLoaded');
    if(!this.spectralIndicesLoaded && hypercubeSatus == "ok")
    {
      this.getSpectralIndices();
    }
  }
/*
  private apps: Appstore[] = [
    {
      id: 8,
      name: 'Spectral index',
      image: 'assets/images/apps/prisme.png',
      description: 'Spectral index',
      rating: 5,
      owned: true,
      values: [],
      parametername: 'index',
      params: [
        { name: 'index', type: 'select', label: 'Spectral index type', defaultValue: '' },
        { name: 'color1', type: 'color', label: 'Couleur 1', defaultValue: '#00008b' },
        { name: 'color2', type: 'color', label: 'Couleur 0', defaultValue: '#ffc0cb' },
        { name: 'color3', type: 'color', label: 'Couleur -1', defaultValue: '#8b0000' }
      ]
     },
     {
      id: 1,
      name: 'Détection de bâtiments',
      image: 'assets/images/apps/building.png',
      description: 'Détection de bâtiments',
      rating: 4,
      owned: true,
      params: [
        { name: 'resolutionQuality', type: 'quality', label: 'Qualité de Résolution', defaultValue: 64, 
        labels: {
          1: "Faible",
          2: "Moyenne",
          3: "Haute"
        },
        values: {
          1: 32,
          2: 64,
          3: 128
        } }
      ]
     }
    ,
    {
      id: 2,
      name: 'Détection d\'arbres',
      image: 'assets/images/apps/tree.png',
      description: 'Détection d\'arbres',
      rating: 5,
      owned: true
     }
     ,
    {
      id: 3,
      name: 'Détection d\'eaux profondes',
      image: 'assets/images/apps/river.png',
      description: 'Détection d\'eaux profondes',
      rating: 5,
      owned: true
     }
     ,
    {
      id: 4,
      name: 'Détection de véhicules',
      image: 'assets/images/apps/vehicles.png',
      description: 'Détection de véhicules',
      rating: 5,
      owned: true
     }
     ,
     {
       id: 5,
       name: 'App 5',
       image: 'image5.png',
       description: 'Ceci est la description de l\'app 5',
       rating: 5,
       owned: false
      }
      ,
     {
       id: 6,
       name: 'App 6',
       image: 'image6.png',
       description: 'Ceci est la description de l\'app 6',
       rating: 5,
       owned: false
      }
      ,
     {
       id: 7,
       name: 'App 7',
       image: 'image7.png',
       description: 'Ceci est la description de l\'app 7',
       rating: 5,
       owned: false
      }
      
  ];
*/
private apps: Appstore[] = [
  
  {
    id: 8,
    name: 'APP_SPECTRALINDEX_NAME_KEY',
    image: 'assets/images/apps/prisme.png',
    description: 'APP_SPECTRALINDEX_DESCRIPTION_KEY',
    rating: 5,
    owned: true,
    values: [],
    parametername: 'index',
    params: [
      { name: 'index', type: 'select', label: 'Spectral index type', defaultValue: '' },
      { name: 'color1', type: 'color', label: 'Couleur 1', defaultValue: '#00008b' },
      { name: 'color2', type: 'color', label: 'Couleur 0', defaultValue: '#ffc0cb' },
      { name: 'color3', type: 'color', label: 'Couleur -1', defaultValue: '#8b0000' }
    ]
   },
   {
    id: 9,
    name: 'APP_BUILDINGDETECTION_NAME_KEY',
    image: 'assets/images/apps/building.png',
    description: 'APP_BUILDINGDETECTION_DESCRIPTION_KEY',
    rating: 5,
    owned: true,
    parametername: 'index',
    parameterval: 'UI',
    parametername2: 'threshold',
    parameterval2: '0.2',
    color:[150, 0, 0],

   },
   {
    id: 10,
    name: 'APP_TREEDETECTION_NAME_KEY',
    image: 'assets/images/apps/tree.png',
    description: 'APP_TREEDETECTION_DESCRIPTION_KEY',
    rating: 5,
    owned: true,
    parametername: 'index',
    parameterval: 'NDVI',
    parametername2: 'threshold',
    parameterval2: '0.7',
    color:[0, 150, 0],
   },
   {
    id: 11,
    name: 'APP_DEEPWATER_NAME_KEY',
    image: 'assets/images/apps/river.png',
    description: 'APP_DEEPWATER_DESCRIPTION_KEY',
    rating: 5,
    owned: true,
    parametername: 'index',
    parameterval: 'TWI',
    parametername2: 'threshold',
    parameterval2: '0',
    color:[0, 0, 150],
   }
   ,
   {
    id: 12,
    name: 'APP_IRON_NAME_KEY',
    image: 'assets/images/apps/iron.png',
    description: 'APP_IRON_DESCRIPTION_KEY',
    rating: 5,
    owned: true,
    parametername: 'index',
    parameterval: 'TWI',
    parametername2: 'threshold',
    parameterval2: '0',
    color:[0, 0, 150],
   }
   ,
   {
    id: 13,
    name: 'APP_PCA_NAME_KEY',
    image: 'assets/images/apps/pca.png',
    description: 'APP_PCA_DESCRIPTION_KEY',
    rating: 5,
    owned: true,
    parametername: 'model',
    parameterval: 'PCA',
    parametername2: '',
    parameterval2: '',
    color:[0, 0, 150],
   }
   ,
   {
    id: 14,
    name: 'APP_ICA_NAME_KEY',
    image: 'assets/images/apps/pca.png',
    description: 'APP_ICA_DESCRIPTION_KEY',
    rating: 5,
    owned: true,
    parametername: 'model',
    parameterval: 'FastICA',
    parametername2: '',
    parameterval2: '',
    color:[0, 0, 150],
   }
   ,
   /*{
    id: 1,
    name: 'APP_BUILDINGDETECTION_NAME_KEY',
    image: 'assets/images/apps/building.png',
    description: 'APP_BUILDINGDETECTION_DESCRIPTION_KEY',
    rating: 4,
    owned: true,
    params: [
      { name: 'resolutionQuality', type: 'quality', label: 'Qualité de Résolution', defaultValue: 64, 
      labels: {
        1: "Faible",
        2: "Moyenne",
        3: "Haute"
      },
      values: {
        1: 32,
        2: 64,
        3: 128
      } }
    ]
   }
  ,
  {
    id: 2,
    name: 'APP_TREEDETECTION_NAME_KEY',
    image: 'assets/images/apps/tree.png',
    description: 'APP_TREEDETECTION_DESCRIPTION_KEY',
    rating: 5,
    owned: true
   }
   ,
  {
    id: 3,
    name: 'APP_DEEPWATER_NAME_KEY',
    image: 'assets/images/apps/river.png',
    description: 'APP_DEEPWATER_DESCRIPTION_KEY',
    rating: 5,
    owned: true
   }
   ,
  {
    id: 4,
    name: 'APP_VEHICULEDETECTION_NAME_KEY',
    image: 'assets/images/apps/vehicles.png',
    description: 'APP_VEHICULEDETECTION_DESCRIPTION_KEY',
    rating: 5,
    owned: true
   }
   ,*/
   {
     id: 5,
     name: 'App 5',
     image: 'image5.png',
     description: 'app 5',
     rating: 5,
     owned: false
    }
    ,
   {
     id: 6,
     name: 'App 6',
     image: 'image6.png',
     description: 'app 6',
     rating: 5,
     owned: false
    }
    ,
   {
     id: 7,
     name: 'App 7',
     image: 'image7.png',
     description: 'app 7',
     rating: 5,
     owned: false
    }
    
];

  async getApps(): Promise<Appstore[]> {
    console.log('getApps');
    //this.apps[0].values =  await this.getSpectralIndices();
    console.log(this.apps[0].values);
    return this.apps;
  }

  async getSpectralIndices(): Promise<string[]>{
    console.log('get spectral indices from appstore service');
    let shortNames: string[] = [];
    let longNames: string[] = [];
    let valuesInfos: {};
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')  // Assurez-vous que le préfixe "Bearer " est correct.
      },
      credentials: 'include' as RequestCredentials  // Important si vous utilisez des sessions ou des cookies.
    };

    return fetch(`${this.apiUrl}/${this.endPointSpectralIndices}`, options)
    .then(response => response.json())
    .then((data) => {
      console.log(data);
      if(data && Object.keys(data).length > 0){
        shortNames = data.map(item => item.short_name);
        longNames = data.map(item => item.long_name);
        console.log(shortNames);
        this.spectralIndicesLoaded = true;
        //this.apps[0].values = shortNames;
        this.apps[0].values = longNames;

        this.apps[0].valuesInfo = data.map(item => ({
            value: item.long_name,
            id: item.short_name,
            infoHTML: this.generateInfoHTML(item)
        }))
        console.log(this.apps[0].valuesInfo);
        
      }
      else
      {
        shortNames = [];
      }
      return shortNames
    });
  }

  generateInfoHTML(data: any): string {
    //console.log('generateInfoHTML');
    //console.log(data);
    return `
        <div>
 
            <h2 class="application-title">${data.short_name}</h2>
            <p class="long-name"><strong>Name :</strong> ${data.long_name}</p>
            <p class="domain"><strong>Application domain :</strong> ${data.application_domain}</p>
            <p class="formula"><strong>Formula :</strong> ${data.formula}</p>
            <div class="bands">
                <strong>Bands :</strong>
                <ul>
                    ${data.bands.map(band => `<li>${band}</li>`).join('')}
                </ul>
            </div>
            <p class="reference"><strong>Reference :</strong> <a href="${data.reference}" target="_blank">${data.reference}</a></p>
        </div>
    `;
}



}

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, map, take } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  isAuth = false;
  constructor(private authService: AuthService, private router: Router) {

    /*this.authService.isAuthenticated$.subscribe(
      (value) => {
        console.log('MAJ de isAuthenticated. Valeur :');
        console.log(this.isAuth);
        this.isAuth = value;
       }
    );*/
  }
/*
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    
    var userItem = localStorage.getItem('user');
    var user = null
    if(userItem)
    {
      user = JSON.parse(userItem);
    }

    if (user) {
      return true;
    }
    this.router.navigate(['/login']);
    return false;

  }*/

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log(localStorage.getItem('token'));
    return this.authService.isAuthenticated$.pipe(
      take(1),
      map(isAuthenticated => {
        console.log('isTokenValid');
        console.log(this.authService.isTokenValid());
        if (isAuthenticated && this.authService.isTokenValid()) {
          console.log('isAuth');

          return true;
        } else {
          console.log('Not Auth');
          
          // Si l'utilisateur n'est pas authentifié, redirigez-le vers la page de connexion
          return this.router.createUrlTree(['/login']);
        }
      })
    );


    /*if(this.isAuth)
    {
      console.log('isAuth');
      
      return true;
    }
    else
    {
      console.log('Not Auth');
          return this.router.createUrlTree(['/login']);
    }*/

  }



}


import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule} from '@angular/material/menu';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CartographyComponent } from './cartography/cartography.component';
import { HypercubeComponent } from './hypercube/hypercube.component';
import { ReportingComponent } from './reporting/reporting.component';
import { BandSelectorComponent } from './band-selector/band-selector.component';
import { LibraryComponent } from './library/library.component';
import { MatTreeModule } from '@angular/material/tree';
import { EditNodeDialogComponent } from './edit-node-dialog/edit-node-dialog.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgChartsModule } from 'ng2-charts';
import { CodeeditorComponent } from './codeeditor/codeeditor.component';
//import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
//import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';
import { MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { UserComponent } from './user/user.component';
import { JwtModule } from '@auth0/angular-jwt';
//import { PlotlyViaCDNModule } from 'angular-plotly.js';
//import * as PlotlyJS from 'plotly.js';
import * as PlotlyJS from 'plotly.js/dist/plotly.js';
import { PlotlyModule } from 'angular-plotly.js';
import { HypercubeSelectorComponent } from './hypercube-selector/hypercube-selector.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ToastrModule } from 'ngx-toastr';
import { AppWizardComponent } from './app-wizard/app-wizard.component';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PreferencesComponent } from './preferences/preferences.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { AngularSplitModule } from 'angular-split';
import { InputdialogComponent } from './inputdialog/inputdialog.component';
import { RendezvousComponent } from './rendezvous/rendezvous.component';

import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { CartComponent } from './cart/cart.component';
import { CatalogComponent } from './catalog/catalog.component';

PlotlyModule.plotlyjs = PlotlyJS;


//PlotlyViaCDNModule.setPlotlyVersion('2.26.0', 'cloudflare'); // can be `latest` or any version number (i.e.: '1.40.0')
//PlotlyViaCDNModule.setPlotlyBundle('basic'); // optional: can be null (for full) or 'basic', 'cartesian', 'geo', 'gl3d', 'gl2d', 'mapbox' or 'finance'

const appRoutes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: '', component: DashboardComponent},
  //{path: 'cartography', component: CartographyComponent},
  //{path: 'hypercube', component: HypercubeComponent},
  //{path: 'reporting', component: ReportingComponent},
];

export function tokenGetter() {
  return localStorage.getItem('token');
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    //DashboardComponent,
    //HypercubeComponent,
    ReportingComponent,
    EditNodeDialogComponent,
    ConfirmationDialogComponent,
    InfoDialogComponent,
    InputdialogComponent,
    //CatalogComponent,
    //CartComponent,
    //RendezvousComponent,
    //PreferencesComponent,
    //AppWizardComponent,
    //HypercubeSelectorComponent,
    //UserComponent,
    //LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    FormsModule,
    RouterModule.forRoot(appRoutes),
    CartographyComponent,
    HypercubeComponent,
    LoginComponent,
    UserComponent,
    MatTreeModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    NgChartsModule,
    MonacoEditorModule,
    HttpClientModule,
    PlotlyModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    ToastrModule.forRoot({
      timeOut: 0,
      extendedTimeOut: 0,
      positionClass: "toast-bottom-left",
      preventDuplicates: true,
      closeButton: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
       // allowedDomains: ["http://localhost:3000"],  // Remplacez par votre domaine backend
        disallowedRoutes: []  // Vous pouvez spécifier des routes si nécessaire
      },
    })
  ],
  exports:[
    RouterModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component } from '@angular/core';

@Component({
  selector: 'app-inputdialog',
  templateUrl: './inputdialog.component.html',
  styleUrls: ['./inputdialog.component.css']
})
export class InputdialogComponent {

}

import { Component, OnInit, OnDestroy  } from '@angular/core';
import { ComponentVisibilityService } from './component-visibility.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy{
  title = 'HYP4U';
  cartographyVisible = true;
  hypercubeVisible = false;
  loginVisible = false;

  private cartographySubscription!: Subscription;
  private hypercubeSubscription!: Subscription;
  private loginSubscription!: Subscription;

  constructor(private componentVisibilityService: ComponentVisibilityService) {}

  ngOnInit(): void {
    this.cartographySubscription = this.componentVisibilityService.getCartographyVisibility().subscribe(isVisible => {
      this.cartographyVisible = isVisible;
      //this.hypercubeVisible = !isVisible; // ou une autre logique si nécessaire
      //this.loginVisible = !isVisible;
      console.log(this.cartographyVisible);
      console.log(this.hypercubeVisible);
      console.log(this.loginVisible);
    });
    this.hypercubeSubscription = this.componentVisibilityService.getHypercubeVisibility().subscribe(isVisible => {
      //this.cartographyVisible = !isVisible;
      this.hypercubeVisible = isVisible; // ou une autre logique si nécessaire
      //this.loginVisible = !isVisible;
    });
    this.loginSubscription = this.componentVisibilityService.getLoginVisibility().subscribe(isVisible => {
      //this.cartographyVisible = !isVisible;
      //this.hypercubeVisible = !isVisible; // ou une autre logique si nécessaire
      this.loginVisible = isVisible;
    });
  }

  ngOnDestroy(): void {
    this.cartographySubscription.unsubscribe();
    this.hypercubeSubscription.unsubscribe();
    this.loginSubscription.unsubscribe();
  }
}

import { Component, inject, OnInit, OnDestroy, ViewChild, ViewEncapsulation, TemplateRef, ChangeDetectorRef, AfterViewInit, ViewContainerRef, Output, EventEmitter  } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ComponentVisibilityService } from '../component-visibility.service';
import { Subscription } from 'rxjs';
import { PreferencesComponent } from '../preferences/preferences.component';
import { CartComponent } from '../cart/cart.component';
import { AppstoreComponent } from '../appstore/appstore.component';
import { CartographyComponent } from '../cartography/cartography.component';
import { HypercubeComponent } from '../hypercube/hypercube.component';
import { LoginComponent } from '../login/login.component';
import { UserComponent } from '../user/user.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule} from '@angular/material/menu';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Overlay, OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { PortalModule, TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  encapsulation: ViewEncapsulation.None,
  imports: [PreferencesComponent, CartographyComponent, HypercubeComponent, LoginComponent, UserComponent, MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    TranslateModule,
    PortalModule,
    CartComponent, OverlayModule]
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  private breakpointObserver = inject(BreakpointObserver);

  @ViewChild('preferences') appStore!: PreferencesComponent;
  @ViewChild('cart') cart!: CartComponent;

  @ViewChild('contextMenu') contextMenu!: TemplateRef<any>;
  private overlayRef: OverlayRef | null = null;

  @ViewChild('cartography') cartography!: CartographyComponent;


  cartographyVisible = true;
  hypercubeVisible = false;
  loginVisible = false;

  private cartographySubscription!: Subscription;
  private hypercubeSubscription!: Subscription;
  private loginSubscription!: Subscription;

  constructor(private componentVisibilityService: ComponentVisibilityService, private translate: TranslateService, private overlay: Overlay, private cdr: ChangeDetectorRef, private viewContainerRef: ViewContainerRef) {
    this.setMapVisibility(true);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  handlePreferencesButtonClick(event: MouseEvent): void {
    console.log('handlePreferencesButtonClick');
    this.appStore.togglePreferences();
  }

  handleCartButtonClick(event: MouseEvent): void {
    console.log('handleCartButtonClick');
    this.cart.toggleCart();
  }

  setMapVisibility(isVisible: boolean) {
    console.log('setMapVisibility');
    this.componentVisibilityService.setCartographyVisibility(isVisible);
    this.componentVisibilityService.setHypercubeVisibility(!isVisible);
    this.componentVisibilityService.setLoginVisibility(!isVisible);
  }

  setHypercubeVisibility(isVisible: boolean) {
    this.componentVisibilityService.setCartographyVisibility(!isVisible);
    this.componentVisibilityService.setHypercubeVisibility(isVisible);
    this.componentVisibilityService.setLoginVisibility(!isVisible);
  }

  setLoginVisibility(isVisible: boolean) {
    console.log('setLoginVisibility');
    this.componentVisibilityService.setLoginVisibility(isVisible);
    this.componentVisibilityService.setCartographyVisibility(!isVisible);
    this.componentVisibilityService.setHypercubeVisibility(!isVisible);
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

    ngOnInit(): void {
      this.cartographySubscription = this.componentVisibilityService.getCartographyVisibility().subscribe(isVisible => {
        this.cartographyVisible = isVisible;
        //this.hypercubeVisible = !isVisible; // ou une autre logique si nécessaire
        //this.loginVisible = !isVisible;
        console.log(this.cartographyVisible);
        console.log(this.hypercubeVisible);
        console.log(this.loginVisible);
      });
      this.hypercubeSubscription = this.componentVisibilityService.getHypercubeVisibility().subscribe(isVisible => {
        //this.cartographyVisible = !isVisible;
        this.hypercubeVisible = isVisible; // ou une autre logique si nécessaire
        //this.loginVisible = !isVisible;
      });
      this.loginSubscription = this.componentVisibilityService.getLoginVisibility().subscribe(isVisible => {
        //this.cartographyVisible = !isVisible;
        //this.hypercubeVisible = !isVisible; // ou une autre logique si nécessaire
        this.loginVisible = isVisible;
      });
    }
  
    ngOnDestroy(): void {
      this.cartographySubscription.unsubscribe();
      this.hypercubeSubscription.unsubscribe();
      this.loginSubscription.unsubscribe();
    }
/*
    openContextMenu(event: MouseEvent) {
      event.preventDefault();
      const positionStrategy = this.overlay.position().flexibleConnectedTo({ x: event.clientX, y: event.clientY })
      .withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top'
      }]);
      this.overlayRef = this.overlay.create({ positionStrategy });
      const portal = new TemplatePortal(this.contextMenu, this.viewContainerRef);
      this.overlayRef.attach(portal);

      this.overlayRef.outsidePointerEvents().subscribe(() => {
        this.closeContextMenu();
      });
    }

    closeContextMenu(): void {
      if (this.overlayRef) {
        this.overlayRef.dispose();
        this.overlayRef = null; 
      }
    }

    addRoiForRendezVous(): void {
      this.cartography.addRoiForRendezVous();
      this.closeContextMenu();
    }
*/
}
